import React from "react";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import { Link, graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { Leistungen, UeberUns, Anfahrt } from "../components/Startseite";

const components = { Leistungen, UeberUns, Anfahrt, Link };

export default function Home({ data: { mdx } }) {
  return (
    <Layout landing>
      <Seo
        title="HR-Sanierung in Salzgitter"
        description={mdx.frontmatter.description}
      />
      <MDXProvider components={components}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  );
}

export const query = graphql`
  query INDEX_PAGE_QUERY {
    mdx(frontmatter: { path: { eq: "startseite" } }) {
      id
      body
      frontmatter {
        title
        description
      }
    }
  }
`;
