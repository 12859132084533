import React from "react";
import Formular from "./Formular";

import anfahrt from "../images/anfahrt.jpg";

export function UeberUns({ children }) {
  return (
    <section id='ueberUns'>
      <div className='ueberUns-container section-wrapper row'>
        <div className='ueberUns_text col-sm-12 col-xl-6 col-xx-6'>
          {children}
        </div>
        <div className='ueberUns_form col-sm-12 col-xl-6 col-xx-6'>
          <p>
            Brand- und Wasserschäden? Wir sind für Sie da! Egal ob privat oder
            im Industriebereich - unsere Firma bietet Ihnen schnelle und
            professionelle Lösungen. 
            Kontaktieren Sie uns jetzt über unser
            praktisches Kontaktformular und lassen Sie uns Ihr Problem lösen!
          </p>
          <Formular />
        </div>
      </div>
    </section>
  );
}

export function Anfahrt({ data }) {
  return (
    <section id='anfahrt'>
      <div className='section-wrapper'>
        <h2>Anfahrt</h2>
        <a
          href='https://goo.gl/maps/UP4R7zQKM5D22diq8'
          target='_blank'
          rel='noreferrer'
        >
          <img src={anfahrt} alt='Anfahrtskarte von Google Maps' />
        </a>
      </div>
    </section>
  );
}

export function Leistungen({ children }) {
  return (
    <section id='leistungen'>
      <div className='section-wrapper'>
        <div className='leistungen-container row'>{children}</div>
      </div>
    </section>
  );
}
